.middle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.down {
    flex-direction: column;
}

.full-vh {
    min-height: 100vh;
}

.text-center {
    text-align: center;
}

.w50 {
    width: 50%;
}

.w30 {
    width: 30%;
}

.m2 {
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.mb2 {
    margin-bottom: 2rem;
}

.border-card {
    border: 2px rgba(40, 40, 40, 0.3) solid !important;
}

body,
#root {
    min-height: 100vh;
}

@media screen and (max-width:992px) {
    .w50 {
        width: 90%;
    }
    .w30 {
        width: 90%;
    }
}